
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class Add extends Vue {
  private info = {
    projectId: '',
    messageTitle: '',
    messageContent: '',
    messageObject: '0,1',
    startTime: '',
    endTime: ''
  }

  private messageObject: string[]= ['0', '1']

  private rules= {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    messageTitle: [
      { required: true, message: '请输入公告标题', trigger: 'change' }
    ],
    messageContent: [
      { required: true, message: '请输入公告内容', trigger: 'change' }
    ],
    messageObject: [
      { required: true, message: '请选择发布对象', trigger: 'change' }
    ],
    startTime: [
      { required: true, message: '请选择展示周期', trigger: 'change' }
    ]
  }

  private date: string[]= []

  private submitShow = false

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.$route.params.id && this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.notice.selectMessageByMessageId, {
      messageId: this.$route.params.id
    }).then((res) => {
      this.info = res
      this.date = [res.startTime, res.endTime]
      this.messageObject = res.split(',')
    })
  }

  // 发布对象变化
  messageChange (value: string[]) {
    if (value.length === 2) {
      this.info.messageObject = '0,1'
    } else {
      this.info.messageObject = value.join()
    }
  }

  // 日期变化
  dateChange (date: string[]) {
    if (date) {
      this.info.startTime = date[0]
      this.info.endTime = date[1]
    } else {
      this.info.startTime = ''
      this.info.endTime = ''
    }
  }

  // 效验表单
  verification () {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid) {
        this.onSubmit()
      }
    })
  }

  onSubmit () {
    this.submitShow = true
    const url = this.$route.params.id ? this.$apis.notice.updateMessage : this.$apis.notice.insertMessage
    if (this.date && this.date.length > 0) {
      this.info.startTime = this.date[0]
      this.info.endTime = this.date[1]
    }
    this.$axios.post(url, this.info).then(() => {
      this.$message.success('保存成功')
      this.$router.back()
    }).finally(() => {
      this.submitShow = false
    })
  }
}
